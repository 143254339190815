import { facetConfig, facetDefaults } from '@config/facetConfig';
import { searchStore } from '@context/searchStore';
import Seo from '@elements/Seo';
import buildSearchBody from '@helpers/buildSearchBody';
import { getDomain } from '@helpers/domains';
import { setDefaultQueryValues } from '@helpers/searchQuery';
import Layout from '@layout/Layout';
import { performServerSearch } from '@providers/search';
import SearchLayout from '@search/SearchLayout';
import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export default function SearchPage({ aggregations, hits, resultCount, status, query }) {
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = searchStore((state) => [
    state.searchState,
    state.setSearchState,
  ]);
  const domain = getDomain();

  if (!status) {
    return (
      <Layout>
        <div className="flex h-[50vh] items-center justify-center bg-accent-3">
          <div className="max-w-7xl p-4">
            <h1 className="pt-4 text-center font-primary text-2xl font-light leading-7 text-primary-dark antialiased md:text-[33px] md:leading-[44px] lg:text-[48px] lg:font-light lg:leading-[57px]">
              Zoeken is momenteel niet beschikbaar
            </h1>
          </div>
        </div>
      </Layout>
    );
  }

  const categories = [
    { name: 'Alles', disabled: false, hits: resultCount },
    { name: 'Producten', disabled: true, hits: 0 },
    { name: 'Topics', disabled: true, hits: 0 },
    { name: 'Kennisvragen', disabled: true, hits: 0 },
  ];

  function useDeepEffect(callback, dependencies) {
    const isFirstRender = useRef(true);
    const previousDeps = useRef(dependencies);

    useEffect(() => {
      if (
        isFirstRender.current ||
        !previousDeps.current.every((dep, i) => isEqual(dep, dependencies[i]))
      ) {
        callback();
        previousDeps.current = dependencies;
        isFirstRender.current = false;
      }
    }, dependencies);
  }

  useDeepEffect(() => {
    updateSearchState(query);
    if (hits) setLoading(false);
  }, [query]);

  function updateSearchState(query) {
    const updateFacetState = (type) => {
      const state = Object.entries(query)
        .filter(([key]) => facetConfig[type].includes(key))
        .map(([name, value]) => ({ name, value: type === 'multi' ? value.split('+') : value }));
      return state.length ? state : facetDefaults[type];
    };

    setSearchState({
      ...searchState,
      facets: {
        single: updateFacetState('single'),
        multi: updateFacetState('multi'),
      },
      view: query.view,
      page: query.page,
      sort: query.sort,
      query: query.query,
      bucket: query.bucket,
    });
  }

  return (
    <Layout>
      <Seo
        title="Zoekresultaten"
        description="Vind hier je zoekresultaten."
        url={`https://${domain}/search`}
      />
      <div className="bg-accent-3">
        {/* TODO - ensure lg:px-0 */}
        <h1 className="mx-auto max-w-7xl px-4 pb-8 pt-14 font-primary text-2xl font-light leading-7 text-primary-dark antialiased md:text-[33px] md:leading-[44px] lg:text-[48px] lg:font-light lg:leading-[57px]">
          Alle kennis van BouwZo
        </h1>
      </div>
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 lg:px-0">
          <SearchLayout
            categories={categories}
            loading={loading}
            aggregations={aggregations}
            hits={hits}
            resultCount={resultCount}
          />
        </div>
      </div>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const { req, res, query } = context;
  setDefaultQueryValues(query);

  const body = buildSearchBody(query);
  const response = await performServerSearch(req, res, body, 'document');

  if (response?.status === 200) {
    const { hits, aggregations } = response.data;
    return {
      props: {
        query,
        aggregations,
        hits: hits.hits,
        resultCount: hits.total.value,
        status: true,
      },
    };
  }

  return { props: { status: false } };
}

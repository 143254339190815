import { searchStore } from '@context/searchStore';
import { RadioGroup } from '@headlessui/react';
import { createSearchQuery, defaults } from '@helpers/searchQuery';
import { PositionFacet } from '@search/Facets/FacetComponents/Position';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Checkbox from './FacetComponents/Checkbox';
import Radio from './FacetComponents/Radio';

const CheckboxGroup = ({ facetData, count, loading, facetFamily, facets }) =>
  facetData.values
    .slice(0, count)
    .map((option, index) => (
      <Checkbox
        option={option}
        key={index}
        loading={loading}
        facetFamily={facetFamily}
        facets={facets}
      />
    ));

const RadioGroupComponent = ({
  facetData,
  count,
  loading,
  facetFamily,
  searchState,
  facets,
  handleClearFilter,
}) => {
  const [selected, setSelected] = useState(null);
  const [showClearFilter, setShowClearFilter] = useState(false);

  useEffect(() => {
    const selectedFacet = searchState.facets.single.find(
      (singleFacet) => singleFacet.name === facetFamily,
    );
    if (selectedFacet) {
      const selectedOption = facetData.values.find((x) => x.label === selectedFacet.value);
      setSelected(selectedOption || null);
      setShowClearFilter(!!selectedOption);
    }
  }, [facets]);

  return (
    <>
      {showClearFilter && (
        <button
          className="pr-2 font-secondary font-light text-accent underline"
          onClick={() => {
            handleClearFilter();
            setShowClearFilter(false);
          }}
        >
          herstel filter
        </button>
      )}

      <RadioGroup value={selected} onChange={setSelected}>
        {facetData.values.slice(0, count).map((option, index) => (
          <Radio option={option} key={index} loading={loading} facetFamily={facetFamily} />
        ))}
      </RadioGroup>
    </>
  );
};

const ShowHideItemsButton = ({ facetData, open, handleOnClick }) => {
  if (facetData.values.length <= 6) return null;

  return (
    <button
      className="w-fit pb-6 pt-4 font-secondary font-light leading-5 text-accent underline"
      onClick={handleOnClick}
    >
      {open ? 'toon minder' : 'toon alles'}
    </button>
  );
};

export default function FacetFilter({ facetData, loading, facetFamily, facets }) {
  const [count, setCount] = useState(6);
  const [open, setOpen] = useState(false);
  const searchState = searchStore((state) => state.searchState);
  const router = useRouter();

  const handleClearFilter = () => {
    const facet = searchState.facets.single.find((facet) => facet.name === facetFamily);
    const wildcard = Object.keys(defaults).includes(facet.name) ? '*' : null;
    const query = createSearchQuery(facet.name, wildcard, router);

    if (facet.name === 'DocumentType' && facet.value === 'Referentiedetail') {
      const referentiedetailFields = [
        'ReferenceDetailMethod',
        'ReferenceDetailPosition',
        'ReferenceDetailLayer',
        'ReferenceDetailType',
        'ReferenceDetailLevel',
        'ReferenceDetailBearingStructure',
        'ReferenceDetailFrontFacadeType',
        'ReferenceDetailSideFacadeType',
        'ReferenceDetailFacadeType',
        'ReferenceDetailBearingStructureType',
        'ReferenceDetailFloorType',
      ];

      referentiedetailFields.forEach((field) => {
        delete query.query.query[field];
        delete query.visibleQuery.query[field];
      });
    }

    router.push(query.query, query.visibleQuery, query.scroll);
  };

  const handleOnClick = () => {
    setCount(open ? 6 : facetData.values.length);
    setOpen(!open);
  };

  const renderGroup = () => {
    switch (facetData.type) {
      case 'checkbox':
        return (
          <div className="flex flex-col gap-3">
            <CheckboxGroup
              facetData={facetData}
              count={count}
              loading={loading}
              facetFamily={facetFamily}
              facets={facets}
            />
            {loading ? (
              <div className="w-2/5">
                <Skeleton />
              </div>
            ) : (
              <ShowHideItemsButton
                facetData={facetData}
                open={open}
                handleOnClick={handleOnClick}
              />
            )}
          </div>
        );
      case 'radio':
        return (
          <div>
            <RadioGroupComponent
              facetData={facetData}
              count={count}
              loading={loading}
              facetFamily={facetFamily}
              searchState={searchState}
              facets={facets}
              handleClearFilter={handleClearFilter}
            />
            {loading ? (
              <div className="w-2/5">
                <Skeleton />
              </div>
            ) : (
              <ShowHideItemsButton
                facetData={facetData}
                open={open}
                handleOnClick={handleOnClick}
              />
            )}
          </div>
        );
      case 'position':
        return (
          <div>
            <PositionFacet facetDataValues={facetData.values} />
          </div>
        );
      default:
        return null;
    }
  };

  return renderGroup();
}

import { searchStore } from '@context/searchStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import { Dialog, Tab, Transition } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { createSearchQuery } from '@helpers/searchQuery';
import SearchResults from '@search/SearchResults';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Facets from './Facets';
import SortDropdown from './SortDropdown';

const SearchLayout = ({ categories, loading, aggregations, hits, resultCount }) => {
  const router = useRouter();
  const searchState = searchStore((state) => state.searchState);

  const [isFilterOpen, setFilterOpen] = useState(false);
  const toggleFilter = () => setFilterOpen(!isFilterOpen);

  const setSearchView = (view) => {
    const query = createSearchQuery('view', view, router);
    router.push(query.query, query.visibleQuery, query.scroll);
  };

  const handleViewToggle = () => {
    setSearchView(searchState.view === 'tile' ? 'list' : 'tile');
  };

  return (
    <>
      <Tab.Group>
        {/* Large Screen Tabs */}
        <div className="hidden w-full items-end justify-center md:flex">
          <Tab.List className="flex w-full p-1">
            {categories.map(({ name, disabled, hits }) => (
              <Tab
                key={name}
                disabled={disabled}
                className={({ selected }) =>
                  classNames(
                    'w-1/4 py-2.5 font-secondary text-sm font-bold leading-5 tracking-wide',
                    selected && 'border-b-2 border-accent text-accent outline-none',
                    disabled && 'opacity-50',
                  )
                }
              >
                {`${name} (${hits})`}
              </Tab>
            ))}
          </Tab.List>
        </div>
        {/* Small Screen Tabs */}
        <div className="flex snap-x snap-mandatory overflow-y-auto md:hidden">
          <Tab.List className="flex gap-4 px-3">
            {categories.map(({ name, disabled, hits }) => (
              <Tab
                key={name}
                disabled={disabled}
                className={({ selected }) =>
                  classNames(
                    'w-fit whitespace-nowrap py-2.5 pt-8 text-sm font-medium leading-5',
                    selected && 'border-b-2 border-accent text-accent outline-none',
                    disabled && 'opacity-50',
                  )
                }
              >
                {`${name} (${hits})`}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </Tab.Group>

      <div className="flex flex-col px-3 pt-8 md:hidden">
        <Button
          buttonType="primary"
          label="Filter"
          type="button"
          icon="chevron-right"
          iconPosition="left"
          overrideClass="!max-w-full"
          onClick={toggleFilter}
        />
      </div>

      {/* Filter Panel */}
      <Transition.Root show={isFilterOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setFilterOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-96">
                    <div className="h-full overflow-y-auto bg-white p-8">
                      <div className="space-y-6 pb-16">
                        <div className="flex justify-end">
                          <button
                            type="button"
                            onClick={() => setFilterOpen(false)}
                            aria-label="Close panel"
                          >
                            <Icon iconName="x" color="text-accent" />
                          </button>
                        </div>
                        <Facets loading={loading} aggregations={aggregations} />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex gap-4 bg-functional-contrast pt-10 md:px-3">
        <div className="hidden w-72 md:block">
          <Facets loading={loading} aggregations={aggregations} />
        </div>
        <div className="w-full md:pl-8">
          <div className="flex w-full justify-between pb-4">
            <div className="hidden font-secondary text-primary-dark md:block">
              {loading ? <Skeleton className="w-28 text-xl" /> : <p>{resultCount} resultaten</p>}
            </div>
            <div className="flex gap-3">
              <p className="pt-2 font-secondary font-bold">Sorteer op:</p>
              <SortDropdown />
              <button onClick={handleViewToggle} className="size-11" aria-label="Toggle view">
                <Icon
                  iconName={searchState.view === 'tile' ? 'list' : 'grid'}
                  size="2xl"
                  color="text-accent"
                />
              </button>
            </div>
          </div>
          <SearchResults
            view={searchState.view}
            loading={loading}
            hits={hits}
            resultCount={resultCount}
          />
        </div>
      </div>
    </>
  );
};

SearchLayout.propTypes = {
  hits: PropTypes.array,
  aggregations: PropTypes.object,
  resultCount: PropTypes.number,
  categories: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

SearchLayout.defaultProps = {
  hits: [],
  aggregations: null,
  resultCount: 0,
  categories: [],
};

export default SearchLayout;

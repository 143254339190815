import { searchStore } from '@context/searchStore';
import { userStore } from '@context/userStore';
import Pagination from '@elements/Pagination';
import Thumbnail from '@elements/Thumbnail';
import { useEnvContext } from '@helpers/envProvider';
import { getPageRange } from '@helpers/getPageRange';
import { getThumbnailUrl } from '@helpers/getThumbnailUrl';
import { getFavoritesList } from '@providers/favorite';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import SearchResultCard from './SearchResultCard/index';

const resultsPerPage = {
  resultCard: 10,
  thumbnail: 12,
};

function formateDate(date) {
  if (date == null || date == undefined) return '';
  return date.split('-').reverse().join('-');
}

function getResults(NEXT_PUBLIC_PROXY, hits) {
  const results = hits.map((hit) => ({
    documentId: hit.fields.DocumentId,
    title: hit.highlight?.Title?.length > 0 ? hit.highlight.Title[0] : hit?.fields?.Title,
    status: hit.fields.Status,
    productType: hit.fields.DocumentType,
    publisher: hit.fields?.Publisher,
    thumbnailUrl: getThumbnailUrl(NEXT_PUBLIC_PROXY, hit.fields.DocumentId),
    publicationDate: formateDate(hit.fields?.PublicationDate?.toString()?.split('T')[0]),
    isFavorite: false,
    hasBadge: false,
    searchResultBadge: 'validatedbyisso',
    hit: hit.highlight?.Content?.length > 0 ? `...${hit.highlight?.Content[0]}...` : null,
  }));
  return results;
}

export default function SearchResults({ view, hits, resultCount }) {
  const { environment } = useEnvContext();
  const NEXT_PUBLIC_PROXY = environment['NEXT_PUBLIC_PROXY'];

  const resultCardPageCount = Math.ceil(resultCount / resultsPerPage.resultCard);
  const thumbnailPageCount = Math.ceil(resultCount / resultsPerPage.thumbnail);
  // Gets the current search store
  const searchState = searchStore((state) => state.searchState);
  const { minPage, maxPage } = getPageRange(searchState.page, view);

  const results = getResults(NEXT_PUBLIC_PROXY, hits);

  const { profileId } = userStore();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profileId) {
      const fetchFavorites = async () => {
        const response = await getFavoritesList(profileId);
        if (response && response.status === 200) {
          setDocuments(response.data);
        } else {
          setDocuments([]);
        }
        setLoading(false);
      };
      fetchFavorites();
    } else {
      setLoading(false);
    }
  }, [profileId]);

  const favorites = documents?.map((document) => ({
    documentId: document.document_id,
  }));

  return (
    <div>
      {loading ? (
        <Skeleton />
      ) : view === 'tile' ? (
        <div className="grid grid-cols-1 justify-items-stretch gap-8 md:grid-cols-2 lg:grid-cols-3">
          {results.slice(minPage, maxPage).map((result, index) => {
            return (
              <Thumbnail
                key={index}
                documentId={result.documentId}
                title={result.title}
                documentType={result.productType}
                publisher={result.publisher}
                publicationDate={result.publicationDate}
                isFavorite={favorites?.some((fav) => fav.documentId == result.documentId)}
                thumbnailUrl={result.thumbnailUrl}
                overrideClass="!w-full"
                loading={loading}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-6">
          {results.slice(minPage, maxPage).map((result, index) => {
            return (
              <SearchResultCard
                key={index}
                documentId={result.documentId}
                title={result.title}
                hit={result.hit}
                documentType={result.productType}
                publisher={result.publisher}
                publicationDate={result.publicationDate}
                isFavorite={favorites?.some((fav) => fav.documentId == result.documentId)}
                thumbnailUrl={result.thumbnailUrl}
                loading={loading}
              />
            );
          })}
        </div>
      )}

      <div className="flex items-center justify-center py-8">
        <Pagination
          count={view == 'list' ? resultCardPageCount : thumbnailPageCount}
          loading={loading}
        />
      </div>
    </div>
  );
}

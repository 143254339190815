import Icon from '@elements/Icon';
import { Disclosure } from '@headlessui/react';
import { useRouter } from 'next/router';
import Skeleton from 'react-loading-skeleton';
import { getFacetsFromAggregations } from '../helpers/getFacetsFromAggregations';
import FacetFilter from './FacetFilter';
import LastSearchTerm from './LastSearchTerm';

const FacetDisclosure = ({ option, index, loading, facets }) => (
  <Disclosure defaultOpen key={index}>
    {({ open }) => (
      <div>
        <Disclosure.Button className="flex w-full justify-between py-2 text-left font-secondary font-black tracking-wide">
          {loading ? (
            <div className="w-48 text-2xl">
              <Skeleton />
            </div>
          ) : (
            option.displayName
          )}
          {open ? (
            <Icon iconName="chevron-up" className="text-primary-dark" />
          ) : (
            <Icon iconName="chevron-down" className="text-primary-dark" />
          )}
        </Disclosure.Button>
        <Disclosure.Panel>
          <FacetFilter
            facetData={option}
            loading={loading}
            facetFamily={option.category}
            facets={facets}
          />
        </Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);

export default function Facets({ loading, aggregations }) {
  const facets = getFacetsFromAggregations(aggregations);
  const router = useRouter();

  const handleClearFilters = () => {
    router.push({ pathname: router.pathname }, undefined, { scroll: false });
  };

  return (
    <div>
      <div className="flex items-center justify-between pb-4">
        <div className="font-secondary font-black">Filters</div>
        <button
          className="pr-2 font-secondary font-light text-accent underline"
          onClick={handleClearFilters}
        >
          herstel alles
        </button>
      </div>
      {!loading && <LastSearchTerm />}
      <div className="flex flex-col gap-2">
        {facets.map((option, index) => (
          <FacetDisclosure
            key={index}
            option={option}
            index={index}
            loading={loading}
            facets={facets}
          />
        ))}
      </div>
    </div>
  );
}
